.AdvantagesContainer {
  display: flex;

  .MuiGrid-item {
    display: flex;

    .Text {
      width: $full-width;
      display: flex;
      background-color: #81bb9c80;
      padding: 2rem 0.5rem;
      border-radius: 0.5rem;
      text-align: center;
      font-size: 1.6rem;

      span {
        font-weight: 800;
      }
    }
  }

  .promoCode {
    .Text {
      background-color: unset;
      padding: 0;
      .alignCode {
        width: $full-width;
        text-align: center;
      }

      span {
        font-weight: 800;
      }
    }
  }
}
