.StyledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $full-width;

  .MuiButtonBase-root {
    background: $primary-gradient;
    border-radius: 4rem;
    width: $full-width;
    max-width: 25rem;
    height: 4.5rem;
    text-transform: none;
    box-shadow: none;
    font-size: 1.4rem;

    &.Mui-disabled {
      background: #a6a6a6;
      color: white;
    }
  }
}
