.OfferDetails {
  margin: 1rem 0;
  display: flex;
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .offerDetailImageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      background-color: #fdf0e8;
      border-radius: 50%;
      box-shadow: 0.5rem 0.3rem 0.5rem 0rem lightgrey;
    }

    .offerDetailContent {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-left: 1rem;

      .Title {
        color: $primary;
        margin-bottom: 0;

        .MuiTypography-root {
          font-weight: 600 !important;
          text-align: left;
          font-size: 1.6rem;
          white-space: pre-line;
        }
      }

      .Text {
        .MuiTypography-root {
          font-size: 1.3rem !important;
        }

        span {
          font-weight: 800;
        }
      }
    }
  }
}
