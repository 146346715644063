.Banner {
  width: $full-width;
  height: 20rem;
  background: url("../../../../assets/banner_santevet.png");
  background-size: cover;
  background-position: center;
  margin-bottom: -3rem;
  position: fixed;
  max-width: $max-app-width;
  top: 0;
  z-index: 0;
}
