.MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .headerContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 1rem;
    }

    .Title {
      color: $primary;

      .MuiTypography-root {
        font-weight: 600 !important;
        text-align: center;
        font-size: 2rem;
        white-space: pre-line;
      }
    }

    .Text {
      margin-bottom: 1.5rem;

      .MuiTypography-root {
        font-weight: 550 !important;
        font-size: 1.6rem;
        white-space: pre-line;
        text-align: center;
      }
    }
  }

  .bold {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 800 !important;
    font-size: 1.6rem;
  }
}
