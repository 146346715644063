//https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

/*Typo*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/* abstract */
@import "abstracts/variables";
@import "abstracts/mixins";

//base

/* themes */
@import "theme/theme-config";
/* components */
// -> atoms
@import "components/atoms/Banner/Banner";
@import "components/atoms/StyledButton/StyledButton";
@import "components/atoms/StyledPaper/StyledPaper";
@import "components/atoms/Text/Text";
@import "components/atoms/Title/Title";
@import "components/atoms/SpinnerLoader/SpinnerLoader";

// -> molecules
@import "components/molecules/OfferDetails/OfferDetails";
@import "components/molecules/Footer/Footer";
@import "components/molecules/AdvantagesContainer/AdvantagesContainer";

// -> organisme
@import "components/organisms/OfferDetailsContainer/OfferDetailsContainer";

// -> pages
@import "components/pages/Insurance/Insurance";

// -> templates
@import "components/templates/MainContent/MainContent";

/* layout */

/* global */

:root {
  font-size: 0.625em; /* fallback IE8+ */
  font-size: calc(1em * 0.625);
}

body {
  font-family: "Lato";
  font-size: 1.4rem;
  transition: all 0.5s linear;
  display: flex;
  align-items: center;
  margin: 0;
}

.App {
  width: $full-view-width;
  height: $full-view-height;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Lato";
  background-color: #f9fafb;
}

.MuiTypography-root {
  font-family: "Lato" !important;
  font-weight: 500 !important;
  font-size: 1.4rem;
}
