.Insurance {
  height: 100%;
  width: 100%;
  max-width: $max-app-width;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    background-color: white;
    z-index: 1;
    margin-top: 16rem;
    flex: 1;
  }
}
