.Text {
  width: $full-width;

  p {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 140%;
    white-space: pre-line;
  }

  span {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 140%;
    white-space: pre-line;
  }
}
