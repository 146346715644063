.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  z-index: 2;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: $white;
  box-shadow: 0rem -0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1);

  .FooterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    max-width: $max-app-width;

    .checkbox {
      display: flex;
      padding-top: 0;

      &.Mui-checked {
        color: $primary;
      }

      .MuiSvgIcon-root {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .MuiTypography-root {
      text-align: center;
      font-size: 1.2rem;
      color: $grey;
    }
  }
}
